.rbc-month-row {
	overflow: visible;
}

.show_for_mobole_view {
	display: none;
}

.dashboard_lets_go_box {
	position: relative;
}

@media screen and (max-width: 768px) {
	.hide_for_mobole_view {
		display: none;
	}

	.show_for_mobole_view {
		display: block;
	}

	.mobile_view_login_signup {
	    position: absolute;
	    top: 50%;
	    left: 50%;
	    transform: translate(-50%, -50%);
	}

	.logo_img_mobile{
	    background: #f13743;
   		text-align: center;
	}

	.logo_img_mobile img {
		    width: 85%;
    		margin: 0 auto;
	}

	.artist-main-profile-tophead .profile_action_buttons {
		text-align: right;
	}

	.slot-input-box {
		max-width: 100%;
	}

	.hello-right-text-sec {
		display: none;
	}

	.dashboard_lets_go_box {
		position: unset;
	}

	.inner-artist-info .s-artist-detail {
	    margin-bottom: 30px;
	}
}