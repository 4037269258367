.hello-header{background-repeat: no-repeat;background-size: cover;background-position: center;padding: 113px 90px 34px 172px; border-radius: 0 132px 0 132px !important;}
.hello-header .hello-right-text-sec .head{font-size: 80px;margin-bottom: 36px;}
.hello-header .hello-right-text-sec .sub-head{font-size: 40px;}
.hello-header .hello-right-text-sec .para-text{font-size: 26px;}
.hello-header .hello-left-btn-sec{position: absolute;bottom: 24px;right: 0;}
.hello-left-btn-sec button{font-size: 26px;padding: 8px 60px;}
.look-something-sec{margin: 4% 0 0;}
.look-something-sec .heading-sec{margin: 0 0 0 23px;}
.look-something-sec .heading-sec .head{font-size: 32px;margin-bottom: 3px;}
.look-something-sec .heading-sec .sub-head{font-size: 20px;}
.look-slide-sec{box-shadow: 0px 3px 20px #0000003b;border-radius: 30px;overflow: hidden;margin: 20px 20px;position: relative;}
.look-slide-sec .inner-look-slide{box-shadow: 0px 3px 20px #0000003b;border-radius: 0px 0px 30px 30px;opacity: 1;backdrop-filter: blur(15px);-webkit-backdrop-filter: blur(15px);position: absolute;bottom: 0;width: auto;background: #0000005c;display: flex;padding: 24px;width: 100%;margin-bottom: -108px;}
.look-slide-sec:hover .inner-look-slide{margin-bottom: 0px;transition: 0.6s;}
.look-slide-sec .inner-look-slide .head{font-size: 25px;}
.look-slide-sec .inner-look-slide .sub-head{font-size: 15px;margin-bottom: 0;}
.look-slide-sec .inner-look-slide .look-btn{font-size: 13px; padding: 7px 28px;position: absolute; bottom: 20px;right: 20px;}
.slick-initialized .slick-slide{transition: 0.6s;}

.main-language-sec{position: relative;}
.main-language-sec .heading-sec {margin: 0 0 0 0;}
.main-language-sec .heading-sec .head {font-size: 38px;margin-bottom: 0px;}
.main-language-sec .heading-sec .sub-head {font-size: 25px;}
.main-language-sec .chosen-sec{box-shadow: 1px 3px 16px #ffebed;border: 1px solid #FFEAEB;border-radius: 15px;padding: 40px;}
.main-language-sec .chosen-sec .chosen-left-sec{padding: 16px 0 0;position: relative;}
.main-language-sec .chosen-sec .chosen-left-sec .inner-head{position: absolute;top: 43%;left: 20px;font-size: 38px;line-height: 49px;text-align: center;}
.main-language-sec .chosen-sec .chosen-left-sec img{width: 66%;}
.main-language-sec .chosen-sec .chosen-right-sec{padding: 30px 0 0 40px;}
.main-language-sec .chosen-sec .chosen-right-sec .inner-heading-sec .head,
.main-budget-mtype-sec .inner-budget-mtype-sec .heading-sec .head, .s-head{font-size: 30px;margin-bottom: 0px;}
.main-language-sec .chosen-sec .chosen-right-sec .inner-heading-sec .sub-head{font-size: 25px;}
.main-language-sec .chosen-sec .chosen-right-sec .multiSelectContainer{width: 77%;margin: 50px 0 0;}
.next-btn{position: absolute;right: 50px;bottom: 50px;font-size: 22px;padding: 12px 50px;border-radius: 14px;}

.main-location-sec{padding: 16px 0 0;}
.main-location-sec .heading-sec .head {font-size: 30px;margin-bottom: 0px;}
.main-location-sec .sub-head { font-size: 20px;}
.map-box{margin: 38px 34px 0 0;}
.map-box iframe{width: 100%;height: 500px;box-shadow: 5px 5px 10px #00000029;border: 1px solid #DDDDDD;border-radius: 16px;}
.location-right-sec{padding: 0 0 0 60px;}
.location-right-sec .head-loco-img .loco-box{display: flex;flex-wrap: wrap;gap: 22px;margin: 36px 0;}
.location-right-sec .head-loco-img .loco-box img{ width: 100px; border-radius: 50%; height: 100px;margin-bottom: 10px;}
.location-right-sec .head-loco-img .loco-box .city-name{font-size: 19px;}
.location-right-sec h1{margin-bottom: 0;}
.location-right-sec .multiSelectContainer {width: 50%;}
.main-budget-mtype-sec .inner-budget-mtype-sec .heading-sec .sub-head{font-size: 20px;}
.main-budget-mtype-sec .inner-budget-mtype-sec{box-shadow: 1px 3px 16px #ffe6e8;border: 1px solid #FCFCFC;border-radius: 20px;padding: 30px 50px 70px;margin: 0 0 30px 0;}



.range-slider{margin: 50px 0 20px;}
.range-slider .multi-range-slider{border: none; box-shadow: none;padding: 20px 10px 40px 236px;z-index: 4;}
.range-slider .multi-range-slider *{padding: 1.8px 0;}
.range-slider .multi-range-slider .bar-left, .range-slider .multi-range-slider .bar-inner, .range-slider .multi-range-slider .bar-right{border-radius: 10px;box-shadow: none;}
.range-slider .multi-range-slider .bar-inner{border: solid 2px #F13743;}
.range-slider .multi-range-slider .thumb::before{width: 40px;height: 40px;box-shadow: none;margin: -18px -12px;background-image: url("../assets/images/fevicon.png");background-repeat: no-repeat;background-position: center;}
.range-slider .multi-range-slider .thumb .caption{bottom: 47px;left: 9px;}
.range-slider .multi-range-slider .thumb .caption *{min-width: 60px;font-weight: 500;height: 38px;font-size: 20px;background-color: black;border-radius: 10px;color: white;box-shadow: none;padding: 4px 15px;}
.range-slider .multi-range-slider .thumb .caption .min-caption,
.range-slider .multi-range-slider .thumb .caption .max-caption{content: " ";background-image: url("../assets/images/rupees.png");background-repeat: no-repeat;background-position: top 13px left 10px;padding: 4px 15px 4px 28px;background-size: 15px;}
.range-slider .m-range{position: absolute;top: 15px;left: 5px;width: 250px;z-index: 2;}
.range-slider .multi-range-slider .labels{position: relative;top: -79px;}
.range-slider .multi-range-slider .label{font-size: 25px; font-weight: 600;}
.range-slider .multi-range-slider .label{content: " ";background-image: url("../assets/images/rupees2.png");background-repeat: no-repeat;background-position: 0 11px;background-size: 18px;padding: 0 0 0 20px;display: table;}
.show-range{background: #E3E3E3;display: inline-block;padding: 12px;position: absolute;top: 0;margin: 33px 0 0 45px;backdrop-filter: blur(30px);-webkit-backdrop-filter: blur(30px);z-index: 1;border-radius: 0 34px 0 34px;}
.show-range .head{font-size: 15px;}
.show-range  .range-from-to{display: flex;gap: 21px;}
.show-range  .range-from-to .inner-text{font-size: 13px;color: #444444;}
.show-range  .range-from-to .range-value{font-size: 20px;}
.show-range  .range-from-to .rupee-sign{font-size: 13px;}

.main-filter-sec{ background: #FDFDFD 0% 0% no-repeat padding-box;box-shadow: 0px 3px 16px #00000029;border-radius: 11px;padding: 26px;}
.main-filter-sec .filter-option{display: inline-flex;gap: 22px;flex-wrap: wrap;}
.main-filter-sec h2{font-size: 25px;}
.main-filter-sec .filter-option .form-select{min-width: 240px;flex: 1 0;}
.main-filter-sec .head-sec{margin: 0 0 15px;}
.filter-selected{margin: 22px 0 0;}
.filter-selected .btn-close{vertical-align: middle;font-size: 10px;margin: 0 0 0 3px; color: #444444;}
.filter-selected .badge{font-size: 17px;background: none !important; color: #444444;border-bottom: 1px solid #444444;border-radius: 0;padding: 0 0;margin: 0 22px 0 0;}
.found-heading-sec .head{font-size: 28px;margin-bottom: 0px;}
.found-heading-sec .sub-head{font-size: 20px;}

.artists-card-sec{padding: 26px 0 0;}
.artists-found-card{margin: 40px 0 0;}
.inner-artist-card{display: flex;}
.inner-artist-card{background: #fff;box-shadow: 0px 3px 6px #00000029;border-radius: 106px 24px 24px 24px !important;padding: 24px;margin: 0 0 30px;height: 100%}
.inner-artist-card .avtar-sec{display: grid;text-align: center;}
.inner-artist-card .avtar-sec .avtar-img{display: inline-block;width: 180px;margin: 0 0 20px 0;border-radius: 91px;}
.inner-artist-card .avtar-sec .avtar-img img {
    border-radius: 50%;
}
.music-detail{padding: 0 0 0 62px;}
.music-detail .name{font-size: 26px;margin: 0;}
.from-select-filter{flex-wrap: wrap;}
.from-select-filter .inner-from-select-filter{font-size: 15px;color: #444444;border-right: 1px solid #444444;padding: 0 10px 0 0;}
.from-select-filter .inner-from-select-filter:last-child{border-right:none}
.music-detail .music-short-detail{padding: 14px 0 0;}
.music-detail .music-short-detail .ico-sec{font-size: 26px; margin: 0 10px 0 0;}
.music-detail .music-short-detail .price{font-size: 20px;}
.book-now-btn .book-btn{position: absolute;bottom: 25px;right: 20px;background: #fd3743;color: #fff;padding: 8px 16px;}

.artist-list-like{position: absolute;right: 24px;top: 24px;}
.heart-like-sec{width: 26px;}
.sc-bcXHqe {stroke: #fd3743 !important;}
.inner-adsec{background: #fff;box-shadow: 0px 3px 6px #00000029;border-radius: 24px;padding: 20px 20px; margin-top: 15px;content: " ";background-image: url("../assets/images/heart-bg.png");background-repeat: no-repeat; background-position: bottom right 27px;background-size: auto;}
.inner-adsec img {
    border-radius: 24px;
    min-height: 280px;
    max-height: 280px;
}
.adbutton button{font-size: 26px;padding: 10px 20px;box-shadow: 0px 8px 12px #0000004D;}
.adhead{font-size: 40px;line-height: 50px;}
.inner-adhead-sec{padding: 0 340px 0 30px;}

.breadcrumb{margin: 0 0 60px;}
.breadcrumb-item{font-weight: 400;font-size: 18px;}
.breadcrumb-item+.breadcrumb-item::before{content: ">";color: black;}

.main-artists-list{padding: 0 10%;}
.inner-artist-info {display: flex;align-items: center;}
.inner-artist-info .avtar-img {display: block;width: 180px;margin: 0;border-radius: 91px;padding: 0;}
.inner-artist-info .avtar-img img {
    border-radius: 50%;
}
.inner-artist-info .s-artist-detail {margin: 0 0 0 40px;}
.inner-artist-info .s-artist-detail .name{font-size: 32px;margin: 0;}
.inner-artist-info .s-artist-detail .locotion{font-size: 22px;margin: 0 0 7px;}
.inner-artist-info .s-artist-detail .review-count{font-size: 23px; color: #444444;}
.inner-artist-info .check-now-btn{display: flex;flex-wrap: wrap;align-items: center;gap: 20px;position: absolute;right: 0;bottom: 10px;}
.inner-artist-info .check-now-btn .check-btn {background: #fd3743;color: #fff;padding: 10px 40px;font-size: 20px;}
.s-about-social-sec{margin: 50px 0 0;}
.s-about-social-sec .left-text-sec{display: flex;margin: 0 0 34px;}
.s-about-social-sec .left-text-sec .ico-img{margin: 0 22px 0 0;}
.s-about-social-sec .left-text-sec .ico-img .inner-ico-img{width: 60px;height: 60px;padding: 10px;box-shadow: 4px 4px 16px #e0e0e0;border-radius: 8px;}
.per-lang{display: flex;flex-wrap: wrap;gap: 20px;margin: 14px 0 0;}
.per-lang .inner-per-lang {background: #FDFDFD 0% 0% no-repeat padding-box;border: 1px solid #B8B3B3;border-radius: 32px;padding: 4px 30px;}
.s-about-social-sec .left-text-sec .right-text-sec h2, .main-social-sec h2{font-size: 26px;}

.main-social-sec .inner-social-sec .ico-text-sec img{width: 48px;margin: 0 15px 0 0;}
.main-social-sec .inner-social-sec{font-size: 20px;background: #FFFFFF 0% 0% no-repeat padding-box;box-shadow: 4px 4px 16px #e0e0e0;border-radius: 12px;padding: 22px;margin: 22px 0 0;display: grid;min-height: 92px;}
.main-livetune-details{margin: 60px 0 0;}
.main-livetune-details .s-heading{margin: 0 0 24px;}
.livetune-detail-box{display: flex;flex-wrap: wrap;justify-content: space-between;}
.livetune-detail-box .inner-livetune-detail-box{box-shadow: 4px 8px 16px #E0E0E0;border-radius: 16px;display: inline-block;text-align: center;min-width: 280px;padding: 20px 0 2px;}
.livetune-detail-box .inner-livetune-detail-box h2{font-size: 60px;}
.livetune-detail-box .inner-livetune-detail-box .sub-head{font-size: 22px;}
.preferred-event-box{display: flex;gap: 80px;flex-wrap: wrap;}
.preferred-event-box .inner-preferred-event-box{display: inline-block;text-align: center;}
.preferred-event-box .inner-preferred-event-box .pre-evnt-ico{width: 66px;height: 66px;box-shadow: 4px 4px 16px #e0e0e0;border-radius: 8px;margin: 0 auto;display: flex; padding: 18px;justify-content: center;}
.preferred-event-box .inner-preferred-event-box .sub-head{font-size: 20px;margin: 4px 0 0;}
.rating-sec .star-sec{font-size: 130px;}
.rating-sec .star-sec .star-class{font-size: 100px;vertical-align: baseline;margin: 0 0 0 17px;}
.rating-sec h2{font-size: 50px;}
.rating-sec .sub-head{font-size: 20px;color: #444444;}

.reviews-sec{padding: 0 80px;}
.reviews-sec .reviews-box{display: flex;margin: 0 0 34px;}
.reviews-sec .reviews-box .ico-img {margin: 0 22px 0 0;}
.reviews-sec .reviews-box .ico-img img {
    width: 64px;
    border-radius: 50%;
}
.reviews-detail-sec .name,.reviews-detail-sec .date{font-size: 18px;}
.reviews-detail-sec .like-text{font-size: 21px;margin: 10px 0 10px;}
.main-rate-review-sec{border-bottom: 2px solid #444444;}
.main-rate-review-sec .col-lg-4{border-right: 1px solid #444444;}

.accordion-button{font-weight: bold; font-size: 18px;padding: 28px 0;}
.accordion-button:not(.collapsed){color: #000;background-color:#fff}
.accordion-item{border: 0;border-bottom: 2px solid #444444;}
.accordion-button:not(.collapsed){box-shadow:none}
.accordion-item:last-of-type {border-bottom-right-radius: 0;border-bottom-left-radius: 0;}
.accordion-item:first-of-type {border-top-left-radius: 0;border-top-right-radius: 0;border-top: 2px solid #444444;}
.accordion-body{padding: 0 0 22px;}
.accordion-button:focus{box-shadow: none;}

.star-rate-sec{background: #F13743 0% 0% no-repeat padding-box;border-radius: 5px;color: #fff; padding: 2px 8px;}
.star-rate-sec .star-class{font-size: 13px;vertical-align: baseline;margin: 0 4px 0 0;}
.count-review{font-size: 20px;}

.main-artist-like-box{margin: 30px 0 0;}
.main-artist-like-box .img-sec{width: 160px;height: 160px;margin: 0 auto 10px;border: 1px solid;
    border-radius: 50%;}
.main-artist-like-box .img-sec img {
    border-radius: 50%;
}
.main-artist-like-box .sub-head{font-size: 22px;margin: 0 0 7px;}

.video-photos-sec.nav-tabs, .video-photos-sec.nav-tabs .nav-link{border: 0;font-size: 20px;font-weight: 400;color: #444444;padding: 0 0;margin: 0 8px 0;}
.video-photos-sec.nav-tabs .nav-item.show .nav-link, .video-photos-sec.nav-tabs .nav-link.active {color: #fd3743;font-weight: bold;border-bottom: 1px solid #fd3743;}

.gallery{column-count: 3;} 
.gallery .pics{transition: all 350ms ease;cursor: pointer;margin-bottom: 12px;}
.gallery .pics:hover{filter: opacity(.8);}

@media (max-width: 991px){
    .gallery{column-count: 2;}
}
@media (max-width: 480px){
    .gallery{column-count: 1;}
}

.model{width: 100%;height: 100vh;position: fixed;top: 0;left: 0;display: flex;justify-content: center;align-items: center;background-color: #000;visibility: hidden;opacity: 0;transform: scale(0);overflow: hidden;z-index: 99999;transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;}
.model.open{visibility: visible;opacity: 1; transform:  scale(1);}
.model img{width: auto;max-width: 100%;height: auto;max-height: 100%; display: block;line-height: 0;box-sizing: border-box;padding: 20px 0 20px;margin: 0 auto;}
.model.open svg{position: fixed;top: 30px;right: 30px;font-size: 40px;color: #fff;cursor: pointer;}
.inner-video-sec{margin: 0 0 10px;}
.music-type-slide-sec {box-shadow: 1px 3px 16px #e1e1e1;border: 1px solid #FCFCFC;border-radius: 20px;margin: 20px 10px;padding: 22px;position: relative;}
.music-type-slide-sec input[type="checkbox"] {opacity: 0;}
.music-type-slide-sec.btn-light:not(:disabled):not(.disabled).active, .music-type-slide-sec.btn-light:not(:disabled):not(.disabled):active, .music-type-slide-sec.show>.btn-light.dropdown-toggle {color: #fff;border-color: #FD3743;}
.main-budget-mtype-sec .music-type-text{font-size: 18px;position: absolute;top: 94px;right: 36px;transform: rotate(344deg);width: 180px;text-align: center;}
.main-budget-mtype-sec .next-btn{bottom: 8px;}
.music-type-selected .badge {font-size: 16px;background: #FFE9EA 0% 0% no-repeat padding-box !important;color: #444444;padding: 7px 11px;margin: 0 22px 10px 0;border: 1px solid #F13743;border-radius: 28px;}
.music-type-selected .badge svg{margin: 0 0 0 8px;cursor: pointer;}
.music-type-selected .badge svg path{stroke: #F13743;}

.share-icon{position: relative;color: #F13743;font-size: 28px;margin: -4px 0 0; cursor: pointer;}
.share-btn-icon{list-style: none;padding: 0;margin: 0;display: none;position: absolute;transition: 1s;}
.share-icon:hover .share-btn-icon{display: block;}  

.active_city {
    border: 1px solid red;
}

.new_next_btn {
    font-size: 22px;
    padding: 12px 50px;
    border-radius: 14px;
}
