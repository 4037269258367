/* CcavenuePaymentLink.css */
.redirect-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8f9fa;
    font-family: Arial, sans-serif;
    text-align: center;
    color: #343a40;
  }
  
  .redirect-container h1 {
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 20px;
    color: #007bff;
  }
  
  .payment-button {
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    color: #ffffff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .payment-button:hover {
    background-color: #0056b3;
  }
  