.range-slider {
    width: 100%;
    margin: 24px auto 0;
}

/* AB Range Slider */
#range-slider-ab {
    height: 22px;
    background: #dbd2d2;
    z-index: 1;
  }
  
  #range-slider-ab .range-slider__range {
    background: #ff4141;
  }
  
  #range-slider-ab .range-slider__thumb {
    width: 70px;
    height: 40px;
    border-radius: 4px;
  }
  
  #range-slider-ab .range-slider__thumb[data-lower] {
    /* background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='' width='30' height='30' viewBox='0 0 24 24'%3E%3Cpath d='M3,5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5M11,7A2,2 0 0,0 9,9V17H11V13H13V17H15V9A2,2 0 0,0 13,7H11M11,9H13V11H11V9Z' /%3E%3C/svg%3E")
      #ff4141; */
      background: #000;
      border: solid #ff4141 5px;
      color: #ff4141;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  
  #range-slider-ab .range-slider__thumb[data-upper] {
    /* background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 24 24'%3E%3Cpath d='M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M15,10.5V9A2,2 0 0,0 13,7H9V17H13A2,2 0 0,0 15,15V13.5C15,12.7 14.3,12 13.5,12C14.3,12 15,11.3 15,10.5M13,15H11V13H13V15M13,11H11V9H13V11Z' /%3E%3C/svg%3E")
      #ff4141; */
      background: #000;
      border: solid #ff4141 5px;
      color: #ff4141;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  
  #range-slider-ab .range-slider__thumb[data-lower][data-active] {
    animation: rotate-anti-clockwise 0.9s infinite;
  }
  
  #range-slider-ab .range-slider__thumb[data-upper][data-active] {
    animation: rotate-clockwise 0.9s infinite;
}
  
  @keyframes rotate-clockwise {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
  
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
}
  
  @keyframes rotate-anti-clockwise {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
  
    100% {
      transform: translate(-50%, -50%) rotate(-360deg);
    }
}