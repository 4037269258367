.main-profile-upload-sec{background: #FFFFFF 0% 0% no-repeat padding-box;box-shadow: 0px 3px 16px #00000029;border-radius: 16px;}
.profile-upload-sec{position: relative;display: flex;align-items: center;align-content: center;justify-content: center;min-height: 400px;}
.profile-upload-sec .inner-profile-upload-sec{border: 2px solid #000000;border-radius: 100%;width: 270px; height: 270px;overflow: hidden;display: flex;justify-content: center;align-items: center;align-content: center;}
.upload-btn-sec{position: absolute;bottom: 37px;}
.upload-btn-sec .upload_label{ background: #fff;display: flex;color: red;border-radius: 100%;cursor: pointer;box-shadow: 0px 3px 6px #00000029;width: 64px;height: 64px;align-items: center;align-content: center;justify-content: center;font-size: 30px;}
.profile-text-sec .edit-profile{border: 1px solid #000000;border-radius: 12px;padding: 7px 18px;cursor: pointer;}
.profile-text-sec .edit-profile:hover{box-shadow: 0px 3px 16px #00000029;}
.profile-text-sec .head{margin: 22px 0 26px;border-bottom: 1px solid #000;padding: 0 0 20px;}
.profile-text-sec .head h2{font-size: 24px;margin: 0;}
.profile-text-sec{padding: 20px 0 0 20px;}
.profile-gender{display: flex;gap: 18px;}
