
body .logo-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  max-width: 100%;
}

body .logo-wrap img {
  width: 100%;
}

#Слой_1 {
  width: 100%;
  text-align: center;
}

.st0 {
  stroke: #FD3743;
  fill: #FD3743;
  stroke-dasharray: 920;
  opacity: 1;
  -webkit-animation: 10s logo-animation cubic-bezier(0.67, 0.5, 0.175, 1);
          animation: 10s logo-animation cubic-bezier(0.67, 0.5, 0.175, 1);
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.st2 {
  font-size: 150px;
  letter-spacing: 15px;
}

@-webkit-keyframes logo-animation {
  0% {
    opacity: 0;
    fill: none;
    stroke-dasharray: 920;
  }
  30% {
    opacity: 1;
  }
  50% {
    fill: rgba(255, 255, 255, 0);
  }
  100% {
    opacity: 1;
    stroke-dasharray: 200;
    stroke: transparent;
    fill: #FD3743;
  }
}

@keyframes logo-animation {
  0% {
    opacity: 0;
    fill: none;
    stroke-dasharray: 920;
  }
  30% {
    opacity: 1;
  }
  50% {
    fill: rgba(255, 255, 255, 0);
  }
  100% {
    opacity: 1;
    stroke-dasharray: 200;
    stroke: transparent;
    fill: #FD3743;
  }
}
.st0{fill:none;stroke:#FD3743;stroke-width:2;stroke-miterlimit:10;}
    .st1{font-family:'open sans';}
    .st2{font-size:150px;}

@media (max-width:768px) {
  body .logo-wrap img {
    max-width: 100%;
  }
}
