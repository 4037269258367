.artist-profile-text-sec button{padding: 10px 50px;border-radius: 37px;}
.artist-profile-upload-sec{padding: 0 22px 22px;}
.conditions-sec ul{list-style: none;font-size: 14px;line-height: 25px;padding: 0;margin: 0 0 0 14px;}
.conditions-sec ul li::before {content: "\2022";color: #fd3743;font-weight: bold;display: inline-block;width: 1em;margin-left: -1em;}
.artist-model-sec .modal-body{padding: 0;}
.artist-model-sec .head-sec{background: #F13743; border-top-left-radius: 14px;border-top-right-radius: 14px;padding: 22px 0;}
.artist-model-sec .closeButtonr svg{color: #f13743;background-color: #fff;}
.expert-panel-sec{padding: 36px 44px 30px;background-image: url("../assets/images/expert-bg.png");background-repeat: no-repeat;background-size: cover;background-position: center;}
.inner-expert-panel-sec{border: 1px solid #040404;border-radius: 80px 20px 20px 20px;padding: 20px;}
.inner-expert-panel-sec .img-sec{border: 2px solid #000000;border-radius: 100%;width: 130px;height: 130px;}
.inner-expert-panel-sec ul{list-style-type: ' ★ ';font-size: 14px;line-height: 25px; padding: 0;margin: 0 0 0 14px;}
.inner-expert-panel-sec ul li::marker {color: #f13743;}
.inner-expert-panel-sec h2{border-bottom: 1px solid #000;padding: 8px 0 10px;}

.main-note-text{margin: 14px 0 0;}
.note-text{font-size: 14px;color: #707070;}
.main-id-img-sec{position: relative;align-items: center;align-content: center;justify-content: center;}
.id-img-sec{border: 2px dashed #707070;border-radius: 16px;width: 100%;min-height: 200px;display: flex;justify-content: center;align-items: center;}
.id-img-sec .filepond--root {
     margin-bottom: 0; 
     cursor: pointer;
}
.id-img-sec .filepond--wrapper {
	width: 100%;
}
.id-img-upload{position: absolute;bottom: 49px;left: 0;right: 0;margin: 0 auto;display: inline-table;}
/*.id-img-upload label{background: #fff;display: flex;color: red;border-radius: 100%;cursor: pointer;box-shadow: 0px 3px 6px #00000029;width: 64px; height: 64px;align-items: center;align-content: center; justify-content: center;font-size: 30px;}*/
.img-note{margin: 30px 0 0;text-align: center;}

.artist-dashboard-artist-info-sec .inner-artist-info{background: #FFFFFF 0% 0% no-repeat padding-box;box-shadow: 1px 3px 16px #FEEAEC;border: 0.5px solid #EDE6E7;border-radius: 12px;padding: 22px;}
.main-artist-transacation{background: #FFFFFF 0% 0% no-repeat padding-box;box-shadow: 1px 3px 16px #FEEAEC;border: 0.5px solid #EDE6E7;border-radius: 12px;margin: 25px 0 0;}
.main-artist-transacation .header-sec .head {font-size: 22px;margin: 0;}
.main-artist-transacation .see-all-text {display: block;}
.main-artist-transacation .header-sec{padding: 30px 15px;border-bottom: 1px solid #9F9E9E;}
.artist-dashboard-artist-info-sec .inner-artist-info .avtar-img{width: 128px;}
.artist-dashboard-artist-info-sec .inner-artist-info .s-artist-detail {margin: 0 0 0 20px;}

.inner-transactions-sec{padding: 15px;border-bottom: 1px solid #9F9E9E;}
.inner-transactions-sec:last-child{border: none;}
.inner-transactions-sec .img-sec {width: 60px;border-radius: 34px;}
.inner-transactions-sec .name-head {font-size: 18px;}
.inner-transactions-sec .sub-head {font-size: 14px;}
.main-inner-transactions-sec{max-height: 400px;overflow-y: scroll;}

.earned-sec{background: #FFFFFF 0% 0% no-repeat padding-box;box-shadow: 1px 3px 16px #FEEAEC;border: 0.5px solid #EDE6E7;border-radius: 12px;padding: 12px;}
.earned-sec .img-sec{background: #F13743 0% 0% no-repeat padding-box;border-radius: 12px;width: 70px;height: 70px;display: flex;justify-content: center;align-items: center;}
.earned-sec .img-sec.faint-img-sec{background: #f1374326 0% 0% no-repeat padding-box;}

.artist-main-profile-tophead button{color: #fd3743; border-color: #fd3743;}
.af-edit-sec{border: 1px solid #FD3743;padding: 4px 14px;border-radius: 20px;}

.align-items-self{align-items: self-start !important;}
.main-bill-invoice-sec .edit-sec{position: absolute; right: 10px;top: 10px;font-size: 20px;}
.main-bill-invoice-sec .earned-sec{min-height: 151px;}
.main-bill-invoice-sec .form-check{position: absolute;bottom: 12px;right: 15px;}
.filter-denld-btn{background: #FFFFFF 0% 0% no-repeat padding-box;border: 1px solid #F13743;border-radius: 32px;padding: 6px 12px;}
.filter-denld-btn:hover{box-shadow: 0px 3px 6px #EDE6E7;}
.main-booking-history-sec .head-top-sec{box-shadow: 0px 3px 6px #EDE6E7;border: 1px solid #EDE6E7;border-radius: 12px 12px 0px 0px;padding: 10px 14px;}

table tbody tr{box-shadow: 0px 0px 4px #00000029;border-radius: 8px;border: transparent;    vertical-align: middle;}
table thead{border: transparent;box-shadow: 1px 2px 4px #00000029;border: 0.5px solid #858585;}
table thead tr th{padding: 1rem 0.5rem !important;}
table {border-collapse: separate;border-spacing: 1px 15px;}

.table-scroll {position: relative;width:100%;z-index: 1; margin: auto;overflow-y: scroll;height: 350px;}
.table-wrap {position: relative;}
.table-scroll thead th {background: #ffffff;position: -webkit-sticky;position: sticky;top: 0;}
.main-filter{box-shadow: 0px 3px 6px #EDE6E7;border: 1px solid #EDE6E7;border-radius: 12px;padding: 18px 22px 20px;margin: 15px 0 0;transition: all 1s ease-in;}

/* .main-filter {
	animation: fadeInAnimation ease 1s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
} */


.uploadedProofs {
	list-style: none;
	padding: 0;
}

.uploadedProofs li {
	display: inline-block;
	margin-right: 10px;
	margin-bottom: 10px;
	border: 0.5px solid #707070;
	padding: 10px;
	border-radius: 5px;
	color: #707070;
}

.uploadedProofs li span {
	margin-right: 10px;
}

/* style when focus applied to state and city */
.focus-state-city{
	border: solid black 2px !important;
}

.modal-label-top{
	margin-top: 1rem;
}

.modal-button{
	margin-bottom: 1rem;
	margin-top:2rem;
	border:none;
	background-color:#F13743;
	border-radius:0.3rem;
	color:white;
	padding:1rem;
}

.slot-input-box{
	display: flex;
	flex-direction: column;
	width: 400px;
	margin-left: auto;
	margin-right: auto;
}